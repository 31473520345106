<template>
    <div class="container-xs">
        <p class="m-0 mb-32">
            {{ text }}
        </p>
    </div>
</template>

<script>

export default {
    props:{
        text: {
            type: String
        }
    }
}
</script>