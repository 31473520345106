export const getErrorMessage = (errors) =>{
    const [error] = errors
    return error.message
}

export const isSuccess = (errors) =>{
    return errors == undefined ? true : false
}

export const getData = (data, module) =>{
    console.log(data.data[module])
    return data.data[module]
}