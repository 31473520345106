<template>
    <div class="address-form-group">
        <input type="text" v-model="address" placeholder="cosmos address">
        <a class="button button-primary button-wide-mobile button-sm" @click="handelSubmit" href="javascript:void(0)"><i class="fa fa-spinner fa-spin" v-if="loading"></i>Submit</a>
    </div>
</template>

<script>
import {GET_AMOUNT_FROM_ADDRESS} from '@/utils/graphql'
import { getErrorMessage, getData } from '@/utils/api_response'

export default {
    data () {
        return {
            address: '',
            module_name: 'airdrop',
            loading: false
        }
    },
    methods: {
        handelSubmit() {
            const { address } = this
            if (address === ''){
              this.$emit('handelMessage', 'Please input your address')
              return
            }
            if (address.slice(0, 7) !== 'cosmos1') {
              this.$emit('handelMessage', 'Please input your address starting cosmos1...')
              return
            }
            this.loading = true
            this.$emit('handelMessage', '')
            this.$apollo.query({
                query: GET_AMOUNT_FROM_ADDRESS,
                variables: {
                    acc_address: address,
                }
            }).then((response) => {
                this.loading = false
                const airdrop = getData(response, this.module_name)
                const GAMEAmount = airdrop.amount / Math.pow(10, 6)
                let message = `Your balance is: ${GAMEAmount} GAME.`
                this.$emit('handelMessage', message)
            }).catch((error) => {
                this.loading = false
                let message = getErrorMessage(error.graphQLErrors)
                this.$emit('handelMessage', message)
            })
        }
    }
}
</script>
