import gql from 'graphql-tag'

export const GET_AMOUNT_FROM_ADDRESS = gql`
  query GET_AIRDROP ($acc_address: String!) {
    airdrop (acc_address: $acc_address) {
        denom,
        amount
    }
  }
`
