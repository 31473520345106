<template>
  <div id="app">
    <section id="game-airdrop-app">
      <section class="hero section center-content">
        <Header/>
      </section>
      <section class="section center-content pt-24 pb-24">
        <SearchForm @handelMessage="messageChange"/>
      </section>
      <section class="section center-content pb-128">
        <Balance :text="text"/>
      </section>
    </section>
    <Footer />
  </div>
</template>

<script>
import SearchForm from './components/SearchForm.vue'
import Balance from './components/Balance.vue'
import Header from './layout/Header.vue'
import Footer from './layout/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    SearchForm,
    Balance,
  },
  data () {
    return {
      text: ''
    }
  },
  methods: {
    messageChange (message) {
      this.text = message
    }
  }
}
</script>

<style>
#game-airdrop-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #eceded;
  background: no-repeat center center;
  background-image: url('/game_bg_image.png');
  background-size: cover;
}
</style>
